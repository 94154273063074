import React, {forwardRef} from 'react'
import Translation from '../global/Translation'

import { injectIntl, defineMessages } from 'react-intl'
import debounce from 'lodash/debounce'
import InputNumber from '../global/InputNumber'
import LocalizedLink from '../global/LocalizedLink'
import Alerts from '../alerts/Alerts'

import ProductDisplayName from '../global/ProductDisplayName'
import ProductImage from '../catalog/ProductImage'
import DisplayBrand from '../catalog/rectangleResult/displays/DisplayBrand'
import DisplayPrice from '../catalog/rectangleResult/displays/DisplayPrice'
import DisplayOutletIndicator from '../catalog/rectangleResult/displays/DisplayOutletIndicator'
import DisplayPriceMktools from '../catalog/rectangleResult/displays/DisplayPriceMktools'
import DisplayLotId from '../catalog/rectangleResult/displays/DisplayLotId'
import DisplayUpcomingIndicator from '../catalog/rectangleResult/displays/DisplayUpcomingIndicator'

import LineSegmentation from './LineSegmentation'
import Spinner from '../global/Spinner'
import DisplayTechnology from '../catalog/rectangleResult/displays/DisplayTechnology'
import moment from 'moment'
import mallLarge from '../../assets/img/logos/mall-large.jpg'
import { ElaborationOptionsMap } from '../cut-to-size/CutToSizeMaps'
import LineSegmentationRecurring from './LineSegmentationRecurring'
import ReactTooltip from 'react-tooltip'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import {getNextWorkingDay} from "../../helpers";
import LineSegmentationV2 from './LineSegmentationV2'
import getNumberFormat from "../getNumberFormat";
import CosentinoGlobalIndicator from './indicators/CosentinoGlobalIndicator'
import confirm from '../global/extendedConfirm'
import CustomProductIndicator from "./indicators/CustomProductIndicator"

defineMessages({
    WALL_SAMPLE: {
        id: 'wall_sample',
        description: 'wall_sample',
        defaultMessage: 'Wall Sample'
    },
    CUSTOM_SAMPLE: {
        id: 'custom_sample',
        description: 'custom_sample',
        defaultMessage: 'Customer Sample'
    },
    project_expired_prices_not_charged : {
        id: 'project_expired_prices_not_charged',
        description: 'project_expired_prices_not_charged',
        defaultMessage: 'The quoted price has expired. Please contact our commercial team'
    },
    for_full_trucks_or_containers_desired_delivery_date_get_affect : {
        id: 'for_full_trucks_or_containers_desired_delivery_date_get_affect',
        description: 'for_full_trucks_or_containers_desired_delivery_date_get_affect',
        defaultMessage: 'For full trucks or containers, the desired delivery date affects all items in your order. You can modify the delivery date for all products on the block on the right.'
    },
    changing_the_preferred_delivery_date_will_affect_all_custom_products: {
        id: 'changing_the_preferred_delivery_date_will_affect_all_custom_products',
        description: 'changing_the_preferred_delivery_date_will_affect_all_custom_products',
        defaultMessage: 'Changing the preferred delivery date will affect all custom products in your cart.'
    }
})
class CartLine extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showHelp: false,
            showComment: false,
            slaDate: null,
            hideSlaDateNonCts: false,
        }
    }

    componentDidMount() {
        this.setEstimatedDeliveryDate()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.linesSlaDate !== prevProps.linesSlaDate) {
            this.setEstimatedDeliveryDate()
        }
    }

    shouldShowSlaDate() {
        const { line, linesSlaDateVisibility, isLoadingLinesSlaDate, slaDate } = this.props
        const { hideSlaDateNonCts } = this.state

        // Conditions for displaying the block
        if (isLoadingLinesSlaDate) return true
        if (slaDate) return true

        // Conditions to hide the block
        if (linesSlaDateVisibility && linesSlaDateVisibility[line.lineId]) return false
        if (!line.cutToSize && hideSlaDateNonCts) return false
        if (line.cutToSize && this.props.hideSlaDatesForCts) return false

        return true
    }

    setEstimatedDeliveryDate() {
        const slaDateData = this.props.linesSlaDate && this.props.linesSlaDate[this.props.line.lineId]
        let estimatedDeliveryDate

        //If the slaDate is comming with date (yyyy-mm-dd) we use this date
        if (slaDateData && slaDateData.slaDate) {
            estimatedDeliveryDate = moment(slaDateData.slaDate)
        }
        
        if (slaDateData && slaDateData.naturalDays && !slaDateData.slaDate) {
            //If the slaDate is comming with only natural days we use only natural days to create the date.
            estimatedDeliveryDate = moment().add(slaDateData.naturalDays, 'days')
        }

        if (estimatedDeliveryDate && estimatedDeliveryDate.isValid()) {
            this.setState({slaDate: estimatedDeliveryDate})
        }
    }

    getCheckoutType() {
        if (this.props.isIkeaCenter) return 'ikea'
        else if (this.props.isMktoolsCenter) return 'mktools'
        else if (this.props.isLowesCenter) return 'lowes'
        else if (this.props.userType === 'SHOP') return 'shop'
        else if (this.props.isEndiCenter) return 'endi'
        else return 'default'
    }

    editSegmentation = () => {
        this.props.loadThisSegmentation(this.props.line.segmentation)
        this.props.showSegmentationModal(
            'edit',
            () => this.props.changeCartLineSegmentation(this.props.line),
            this.props.line.productId
        )
    }
    getMin(line) {
        return line.product.min
    }
    getLimit(line) {
        if(line.extra && line.extra.mall){
            return this.getLimitMall(line)
        }
        if (line.product.discontinued) {
            if (this.props.isMktoolsUsaCenter && this.getLimitMktool(line) < this.getLimitDiscontinued(line)) return this.getLimitMktool(line)
            else return this.getLimitDiscontinued(line)
        } else if (this.props.isMktoolsUsaCenter) return this.getLimitMktool(line)
        else if (line.product.isMktool) return this.getLimitMktool(line)
        else if (line.extra && line.extra.outlet) return this.getLimitOutlet(line)
        else return null
    }
    getLimitMktool(line) {
        let result = line.qty
        if (line.product.isMktool) {
            if (this.props.isMktoolsUsaCenter && (line.product.max || line.product.max === 0)) result = line.qty + line.product.max
            else result = result + 1000
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }
    getLimitOutlet(line) {
        let result = line.qty
        if (line.product.stock_outlet) {
            result = result + parseInt(line.product.stock_outlet, 10)
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }
    getLimitDiscontinued(line) {
        let result = line.qty
        if (line.product.stock + line.product.stock_outlet) {
            result = result + parseInt(line.product.stock + line.product.stock_outlet, 10)
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }
    getLimitMall(line) {
        let result = line.qty
        if (line.extra && line.extra.mall && line.extra.outlet && line.product.stock_outlet > 0) {
            result =  result + parseInt(line.product.stock_outlet, 10)
        } else {
            result = result + parseInt(line.product.stock, 10)
        }
        if (this.props.qtyProductCart[line.product.productId]) {
            result = result - parseInt(this.props.qtyProductCart[line.product.productId], 10)
        }
        return result
    }
    saveComment = debounce(() => this.props.saveCartLineComment(this.props.line.lineId, this.inputComment.value), 500)
    getNewPrice() {
        let { line } = this.props
        let price = null
        if (this.props.linesPrice[line.lineId]) {
            let priceYYYY = this.props.linesPrice[line.lineId].price
            price = priceYYYY ? priceYYYY : 0
        }

        let numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

        if(this.hasZT00Price() && this.props.linesPrice[line.lineId].coin === 'JPY') {
            numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
        }

        return price && numberFormat.format(price)
    }
    hasZT00Price() {
        let { line } = this.props
        let priceZT00 = false
        if (this.props.linesPrice[line.lineId]) {
            priceZT00 = this.props.linesPrice[line.lineId].hasZT00
        }
        return priceZT00
    }
    isOutdated() {
        const { line } = this.props
        const linePrice = this.props.linesPrice[line.lineId]
        return !!linePrice && !!linePrice.outdated
    }
    getNewPricePerUnit() {
        let { line } = this.props
        let price = null
        if (this.props.linesPrice[line.lineId]) {
            let priceYYYY = this.props.linesPrice[line.lineId].pricePerUnit
            price = priceYYYY ? priceYYYY : 0
        }
        let numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 2, minimumFractionDigits: 2 })

        if(this.hasZT00Price() && this.props.linesPrice[line.lineId].coin === 'JPY') {
            numberFormat = getNumberFormat(this.props.lang, { maximumFractionDigits: 0, minimumFractionDigits: 0 })
        }

        return price && numberFormat.format(price)
    }
    canViewSLA = () => {
        return this.props.type === 'checkout' && !this.props.isIkeaCenter && !this.props.isLowesCenter && !this.props.isMktoolsCenter
    }

    showLongestDeliveryDate = (storedDesiredDate, slaDate) => {
        let dateToShow = null

        if (storedDesiredDate && slaDate) {
            // If the preferred delivery date is after the sla service date, show the preferred delivery date
            if (storedDesiredDate.isAfter(slaDate)) {
                dateToShow = storedDesiredDate.format('L')
            }

            // If the preferred delivery date is before or the same as the sla service date, show the sla service date
            if (storedDesiredDate.isBefore(slaDate) || storedDesiredDate.isSame(slaDate)) {
                dateToShow = slaDate.format('L')
            }
        } else if (slaDate) {
            // If storedDesiredDate is null, show the sla service date
            dateToShow = slaDate.format('L')
        }

        return dateToShow;
    }

    handleShowSlaDateNonCts = (preferredDate, slaDate, line) => {
        if (!line.cutToSize && preferredDate && slaDate) {
            if (preferredDate.isAfter(slaDate, 'days')) {
                this.setState({ hideSlaDateNonCts: true })
            } else {
                this.setState({ hideSlaDateNonCts: false })
            }
        }
    }

    handleSetAllLinesDeliveryDatesCts = (date, line, slaDateState) => {
        if (line.cutToSize) {
            this.props.setAllLinesDeliveryDatesCts(date, slaDateState)
        }
    }

    confirmPreferredDeliveryDateChange = (onClick) => {
        confirm(this.props.intl.formatMessage({ id: 'changing_the_preferred_delivery_date_will_affect_all_custom_products' }), {
            isV2: true,
            textCancel: this.props.intl.formatMessage({ id: 'cancel' }),
            textOk: this.props.intl.formatMessage({ id: 'confirm' })
        }).then(
            confirm => {
                onClick()
            },
            cancel => {}
        )
    }
    handleShowSlaDate = (preferredDate, slaDate) => {
        if (preferredDate && slaDate) {
            if (preferredDate.isAfter(slaDate, 'days')) {
                this.setState({ showSlaDate: false })
            } else {
                this.setState({ showSlaDate: true })
            }
        }
    }

    render() {
        const { line, onOpenBundleContent, changeCartLineQty, shippingMethod, onToggleFavourite, onDelete, type, intl, isMktoolsCenter, isIkeaCenter, linesDeliveryDates,addCartLine, typeLine, isDisabledPreferedDeliveryDatePicker, isDefaultDeliveryDate } = this.props
        const DesiredDatePicker = forwardRef(({ value, onClick }, ref) => {
            const gtmLabel = 'ec_funnel_compra_fecha_entrega_producto_modificar'
            const tooltipId = line.lineId

            const handleClick = (event) => {
                event.preventDefault()
                if (line.cutToSize && !isDisabledPreferedDeliveryDatePicker) {
                    this.confirmPreferredDeliveryDateChange(onClick)
                } else {
                    onClick()
                }
            }

            if (!desiredDate && !slaDate) return (
                <div onClick={handleClick} className="prefered-date-picker" gtm-label={gtmLabel}>
                    <span
                        data-tip={this.props.intl.formatMessage({
                            id: 'for_full_trucks_or_containers_desired_delivery_date_get_affect',
                            defaultMessage: 'For full trucks or containers, the desired delivery date affects all items in your order. You can modify the delivery date for all products on the block on the left.'
                        })}
                        data-for={tooltipId}
                        style={{ color: isDisabledPreferedDeliveryDatePicker ? '#7D7D7D' : '' }}
                    >
                        <Translation id="set_preferred_date" defaultMessage="Do you want to set the preferred delivery date?"/>
                        {isDisabledPreferedDeliveryDatePicker && (
                            <ReactTooltip id={tooltipId} effect="solid" place="bottom" className="delivery__date__tooltip" />
                        )}
                    </span>
                </div>
            )
            else if (desiredDate && desiredDate !== slaDate) return (
                <div onClick={handleClick} className="prefered-date-picker" gtm-label={gtmLabel}>
                    <span
                        data-tip={this.props.intl.formatMessage({
                            id: 'for_full_trucks_or_containers_desired_delivery_date_get_affect',
                            defaultMessage: 'For full trucks or containers, the desired delivery date affects all items in your order. You can modify the delivery date for all products on the block on the left.'
                        })}
                        data-for={tooltipId}
                        style={{ color: isDisabledPreferedDeliveryDatePicker ? '#7D7D7D' : '' }}
                    >
                        <Translation id="preferred_delivery_date_at" defaultMessage="Preferred delivery date {date}" values={{date:value}} />
                        {isDisabledPreferedDeliveryDatePicker && (
                            <ReactTooltip id={tooltipId} effect="solid" place="bottom" className="delivery__date__tooltip" />
                        )}
                    </span>
                </div>
            )
            else return (
                <div onClick={handleClick} className="prefered-date-picker" gtm-label={gtmLabel}>
                    <span
                        data-tip={this.props.intl.formatMessage({
                            id: 'for_full_trucks_or_containers_desired_delivery_date_get_affect',
                            defaultMessage: 'For full trucks or containers, the desired delivery date affects all items in your order. You can modify the delivery date for all products on the block on the left.'
                        })}
                        data-for={tooltipId}
                        style={{ color: isDisabledPreferedDeliveryDatePicker ? '#7D7D7D' : '' }}
                    >
                    <Translation id="receive_on_another_date" defaultMessage="Do you want to receive it on another date?"/>
                    {isDisabledPreferedDeliveryDatePicker && (
                        <ReactTooltip id={tooltipId} effect="solid" place="bottom" className="delivery__date__tooltip" />
                    )}
                    </span>
                </div>
            )
        })
        
        let qtyToAdd = null
        let image = null
        let todayPlus15Days = new moment().add(15, 'days')
        let today = new moment()
        let launchDate = line.product && line.product.creationDate ? moment(line.product.creationDate) : null
        const slaDate = this.state.slaDate ? this.state.slaDate.format('L') : null
        const storedDesiredDate = linesDeliveryDates[line.lineId] ? linesDeliveryDates[line.lineId] : null
        const desiredDate = storedDesiredDate ? storedDesiredDate.format('L') : null
        const ctsMeasures = !!line.cutToSize && this.props.getCtsMeasure(line.cutToSize)

        if (line.product !== undefined) {
            if (line.extra && line.extra.type === 'custom_sample') {
                qtyToAdd = 6
            } else if (line.cutToSize) {
                qtyToAdd = 1
            } else if (line.product && (line.product.typeId === '01' || line.product.typeId === 'NEWS')) {
                qtyToAdd = 1
            } else {
                qtyToAdd = line.product.minQty[shippingMethod]
            }
        }

        /*if (line.cutToSize && line.cutToSize.thumb) {
            image = <img alt="" src={line.cutToSize.thumb} />
        } else */if ((line.extra && (line.extra.type === 'wall_sample' || line.extra.type === 'custom_sample')) || (line.extra && line.extra.type === 'brochures')) {
            image = <img alt="" src={this.props.getImageBackground(line.product)} />
        } else {
            image = (
                <LocalizedLink className="cart_line__link_image"
                               routeId="ROUTE_PRODUCT_DETAIL"
                               params={{ id: line.product.productId, outlet: line.extra && !!line.extra.outlet ? 'outlet' : ''}}
                               onClick={() => this.props.setLastSelectedProduct(line.product, line.extra)}
                >
                    <ProductImage product={line.product} customClass='recurring_orders_image' />
                </LocalizedLink>
            )
        }
        if (!line || !line.lineId) return null

        if (line.extra && line.extra.itsFree && !this.props.canViewPricesPhase3) return null

        //If the line already had a preferred delivery date and the cart has passed the isDisabledPreferredDeliveryDatePicker condition,
        //we delete the preferred delivery date that already had that position.
        if (linesDeliveryDates && 
            linesDeliveryDates[line.lineId] && 
            isDisabledPreferedDeliveryDatePicker && 
            !isDefaultDeliveryDate) {
            this.props.unsetLineDeliveryDate(line.lineId)
        }

        return (
            <React.Fragment>
                {/*BLOCK recurring orders v2*/}
                {type === 'recurring_orders_v2' ? (
                    <div className={`d-flex ${line.lineId > 3 ? 'hidden-column-v2 ' + line.orderId : ''}`} style={{borderBottom: '1px solid #EBEBEB'}}>
                        <div className="line-item" style={{ height: '4rem', paddingTop: '11px' }}>
                            <div className="img">
                                <DisplayTechnology technology={line.product.technology} />
                                <div className="indicators">{line.extra && line.extra.outlet && <DisplayOutletIndicator outletQuality={line.extra.outlet} />}</div>
                                {image}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ width: '10rem' }}>
                                    <span className="title">{line.cutToSize ? line.cutToSize.colorName : <ProductDisplayName product={line.product} />}</span>
                                </div>
                                <div>
                                    <span style={{ fontSize: '10px' }}>{line.productId}</span>
                                    {
                                        line.product.zaddon === 'MAL' &&
                                        <span> (<Translation id='MAL'/>)</span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{flex: 1}}>
                            <div style={{ display: 'flex', flexFlow: 'column', marginTop: '11px' }}>
                                <span style={{fontSize: '14px'}}>
                                    <span style={{ fontWeight: 'bold', display: 'block', fontSize: '14px' }}>
                                        <Translation id="segmentation" defaultMessage="Segmentation" />
                                    </span>
                                    <LineSegmentationRecurring line={line} />
                                </span>
                            </div>
                        </div>
                        <div style={{flex: 1}}>
                            <div style={{ display: 'flex', flexFlow: 'column', marginTop: '11px', marginLeft: '8px' }}>
                                <span style={{fontSize: '14px'}}>
                                    <span style={{ fontWeight: 'bold', display: 'block', fontSize: '14px' }}>
                                        <Translation id="stock" defaultMessage="Stock" />
                                    </span>
                                    {line.product.stock === 0 ? (
                                        <React.Fragment>
                                            <span
                                                style={{ margin: 0 }}
                                                data-tip={this.props.intl.formatMessage({
                                                    id: 'material_is_currently_out_of_stock',
                                                    defaultMessage: 'This material is currently out of stock but you can add it to your cart. Delivery date may be affected.'
                                                })}
                                            >
                                                <i style={{ color: 'black' }} className="icon far visible fa-info-circle"></i> {line.product.stock}{' '}
                                                <Translation id="tables_available_in_stock" defaultMessage="Tables available in stock" />
                                            </span>
                                            <ReactTooltip />
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {line.product.stock} <Translation id="tables_available_in_stock" defaultMessage="Tables available in stock" />
                                        </React.Fragment>
                                    )}
                                </span>
                            </div>
                        </div>
                        <div style={{flex: 1}}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <div>
                                    <InputNumber
                                        value={line.qty}
                                        change={qty => {
                                            line.qty = qty
                                        }}
                                        size="small"
                                        step={isIkeaCenter && line.product.unit === 'M2' ? 0.01 : qtyToAdd}
                                        limit={this.getLimit(line)}
                                        min={this.getMin(line)}
                                        decimals={isIkeaCenter && line.product.unit === 'M2' ? 2 : 0}
                                    />
                                </div>
                                <button style={{ marginLeft: '1rem' }} className="bt" onClick={() => addCartLine(line.productId, line.qty)} gtm-label="ec_añadir_carrito">
                                    <i className="icon far visible fa-shopping-cart"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    //BLOCK checkout v2
                    typeLine && typeLine === 'checkout_v2' ? (
                        <div className={`line-item checkout`} key={line.lineId}>
                            <div className="img">
                                <DisplayTechnology technology={line.product.technology} />
                                {
                                    this.props.canViewOutletAndNaturalStoneFromHQInOtherCenters &&
                                    line.extra &&
                                    line.extra.isCosentinoGlobal && (
                                        <CosentinoGlobalIndicator />
                                    )
                                }
                                {this.props.line.cutToSize && !this.props.canViewOutletAndNaturalStoneFromHQInOtherCenters && (
                                    <CustomProductIndicator />
                                )}
                                <div className="indicators">
                                    {line.extra && line.extra.outlet && (
                                        <DisplayOutletIndicator
                                            outletQuality={line.extra.outlet}
                                            outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? this.product.outletDeviated : null}
                                        />
                                    )}
                                </div>

                                {image}
                            </div>
                            <div className="content">
                                <div className="description-and-input">
                                    <div className="mini_cart_line_title">
                                        <div className="title">
                                            <LocalizedLink routeId="ROUTE_PRODUCT_DETAIL"
                                                           params={{ id: line.product.productId, outlet: line.extra && !!line.extra.outlet ? 'outlet' : ''}}
                                                           onClick={() => this.props.setLastSelectedProduct(line.product, line.extra)}
                                            >
                                                <span className="title">{line.cutToSize ? line.cutToSize.colorName : <ProductDisplayName product={line.product} />}</span>
                                            </LocalizedLink>{' '}
                                            <span className="subtitle">
                                                {
                                                    this.props.canViewOutletAndNaturalStoneFromHQInOtherCenters && ['SENSA', 'SCALEA', 'PREXURY'].includes(line.brand) ? (
                                                        <React.Fragment>
                                                            <DisplayBrand brand={line.brand} span></DisplayBrand>
                                                            <span> ({<Translation id='natural_stone' defaultMessage='Natural Stone' />}*)</span>
                                                        </React.Fragment>
                                                    ) : (
                                                        <DisplayBrand brand={line.brand} span></DisplayBrand>
                                                    )
                                                }

                                        </span>
                                            {launchDate && line.product.stock == 0 && launchDate > today && launchDate < todayPlus15Days && (
                                                <div className="spheres-indicator">
                                                    <DisplayUpcomingIndicator />
                                                </div>
                                            )}
                                        </div>
                                        <div className="lot_id">
                                            {' '}
                                            {line.bundleId ? (
                                                <span className="quantity-label bundle-content " onClick={() => onOpenBundleContent(line)}>
                                                <i className="fal fa-list" /> <Translation id="bundle_id" defaultMessage="bundle_id" /> {line.bundleId}
                                            </span>
                                            ) : line.lotId ? (
                                                <span className="quantity-label">
                                                <DisplayLotId lotId={line.lotId} withDescription></DisplayLotId>
                                            </span>
                                            ) : null}
                                        </div>
                                        <div className="mini_cart_line_price">
                                            {!line.product.pricesMktools && !(line.extra && line.extra.itsFree) ? (
                                                <React.Fragment>
                                                    {this.props.canViewPricesPhase2 ? (
                                                        <div className="mini_cart_line_info__line" style={{ display: 'block' }}
                                                        >
                                                            {this.props.calculatingLinesPrice ? (
                                                                <Spinner isLoading={true} relative small></Spinner>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <span className='total'>
                                                                        {(this.getNewPricePerUnit() && this.getNewPrice() == 0) || !this.getNewPrice() || !this.hasZT00Price() ? (
                                                                            <Translation id="not_available" defaultMessage="Not available" />
                                                                        ) : (
                                                                            this.getNewPrice()
                                                                        )}{' '}
                                                                        {this.props.linesPrice &&
                                                                            this.props.linesPrice[line.lineId] &&
                                                                            !this.getNewPrice() == 0 &&
                                                                            this.hasZT00Price() && <React.Fragment>{this.props.linesPrice[line.lineId].coin}</React.Fragment>}
                                                                    </span>
                                                                    <br />
                                                                    <span className='price d-flex align-items-center'>
                                                                        {
                                                                            this.isOutdated() && (
                                                                                <span
                                                                                    data-tip={this.props.intl.formatMessage({
                                                                                        id: 'project_expired_prices_not_charged',
                                                                                        defaultMessage: 'Project is expired. Prices for this line are not charged.'
                                                                                    })}
                                                                                >
                                                                                    <i className="alert-signal far fa-exclamation-triangle" />
                                                                                    <ReactTooltip bg-coloreffect="solid" place="bottom" />
                                                                                    &nbsp;&nbsp;
                                                                                </span>
                                                                            )
                                                                        }
                                                                        <b>
                                                                            <Translation id="price" defaultMessage="Price" />:&nbsp;
                                                                        </b>
                                                                        {(this.getNewPricePerUnit() && this.getNewPricePerUnit() == 0) ||
                                                                        !this.getNewPricePerUnit() ||
                                                                        !this.hasZT00Price() ? (
                                                                            <Translation id="not_available" defaultMessage="Not available" />
                                                                        ) : (
                                                                            this.getNewPricePerUnit()
                                                                        )}{' '}
                                                                        {this.props.linesPrice &&
                                                                            this.props.linesPrice[line.lineId] &&
                                                                            !this.getNewPricePerUnit() == 0 &&
                                                                            this.hasZT00Price() && (
                                                                                <React.Fragment>
                                                                                    {this.props.linesPrice[line.lineId].coin} / {this.props.linesPrice[line.lineId].unit}
                                                                                </React.Fragment>
                                                                            )}
                                                                    </span>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="mini_cart_line_info__line">
                                                            {!(line.extra && line.extra.outlet) && (
                                                                <DisplayPrice
                                                                    userPreferences={this.props.userPreferences}
                                                                    priceList={this.props.priceList}
                                                                    userPriceList={this.props.userPriceList}
                                                                    product={line.product}
                                                                    isMktoolsCenter={this.props.isMktoolsCenter}
                                                                    centerId={this.props.centerId}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <div className='price'>
                                                    <DisplayPriceMktools prices={line.product.pricesMktools} group={this.props.priceGroup} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mini_cart_line_block">
                                        <div className="mini_cart_line_info">
                                            {!line.cutToSize && line.product.subtypeName && (
                                                <div className="mini_cart_line_info__line">
                                                    <span> {line.product.subtypeName} </span>
                                                </div>
                                            )}
                                            {line.extra && line.extra.type && (
                                                <div className="mini_cart_line_info__line">
                                                    <span> {intl.formatMessage({ id: line.extra.type })} </span>
                                                </div>
                                            )}
                                            {line.extra && line.extra.brand && <DisplayBrand brand={line.extra.brand}></DisplayBrand>}
                                            {line.extra && line.extra.colorName && (
                                                <div className="mini_cart_line_info__line">
                                                    <span>{line.extra.colorName}</span>
                                                </div>
                                            )}
                                            {line.cutToSize && line.cutToSize.elaboration && (
                                                <div className="mini_cart_line_info__line">
                                                    <div className="subtitle">
                                                        <span> {intl.formatMessage(ElaborationOptionsMap[line.cutToSize.elaboration].name)} </span>
                                                    </div>
                                                </div>
                                            )}
                                            {!line.cutToSize && line.product.finish && (
                                                <div className="mini_cart_line_info__line">
                                                    <span>
                                                        <Translation id={line.product.finish} />
                                                    </span>
                                                </div>
                                            )}
                                            {line.cutToSize && line.cutToSize.finish && (
                                                <div className="mini_cart_line_info__line">
                                                    <div className="subtitle">
                                                        <Translation id={line.cutToSize.finish} />
                                                    </div>
                                                </div>
                                            )}
                                            {line.extra && line.extra.height && line.extra.width && (
                                                <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                                <span>
                                                    <i className="fas fa-window-maximize" />
                                                    &nbsp;
                                                    {this.props.adaptedFormat(line.extra.height + 'X' + line.extra.width, { upper: true, spaced: true })}
                                                </span>
                                                </div>
                                            )}
                                            {line.product.thickness && !line.cutToSize && (
                                                <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                                <span style={{ margin: '0' }}>
                                                    <i className="fas fa-sort-amount-down" />
                                                    {line.product.thickness} cm.{' '}
                                                </span>
                                                </div>
                                            )}
                                            {line.product.zaddon === 'MAL' && (
                                                <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                                    <Translation id='MAL' />
                                                </div>
                                            )}
                                            {line.extra && line.extra.mall && (
                                                <div style={{ position: 'relative' }} className="mini_cart_line_info__line wrapper__logo_mall">
                                                    <img style={{ width: '45px' }} width="45" src={mallLarge} alt="logo mall" />
                                                </div>
                                            )}
                                            {type === 'recurring_orders' &&
                                                (((line.extra && line.extra.type === 'wall_sample') || (line.extra && line.extra.type === 'custom_sample')) && (
                                                    <div className="mini_cart_line_info__line">
                                                        <Translation id="quantity" defaultMessage="Quantity" />: {line.qty}
                                                    </div>
                                                ))}
                                            {line.extra && line.extra.lot && (
                                                <React.Fragment>
                                                    <div className="mini_cart_line_info__line">
                                                        <Translation id="quarryBlock" defaultMessage="Quarry Block" />:{' '}
                                                        <span className="quarry_block">{line.extra.lot.quarryBlock}</span>
                                                    </div>
                                                    <div className="mini_cart_line_info__line">
                                                        <Translation id="quality" defaultMessage="Quality" />: <span className="quality">{line.extra.lot.quality}</span>
                                                    </div>
                                                    <div className="mini_cart_line_info__line">
                                                        <Translation id="shade" defaultMessage="Shade" />: <span className="quality">{line.extra.lot.shade}</span>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            {/*{line.product.pricesMktools && (*/}
                                            {/*    <div className="mini_cart_line_info__line">*/}
                                            {/*        <i className="fas" />*/}
                                            {/*        <DisplayPriceMktools prices={line.product.pricesMktools} group={this.props.priceGroup} />{' '}*/}
                                            {/*    </div>*/}
                                            {/*)}*/}

                                            {line.cutToSize && (
                                                <React.Fragment>
                                                    {line.cutToSize.width && line.cutToSize.height && line.cutToSize.thickness && (
                                                        <React.Fragment>
                                                            <div className="mini_cart_line_info__line">
                                                                <div className="subtitle">
                                                                    <i className="fas fa-window-maximize" />
                                                                    &nbsp;
                                                                    <span>
                                                                    {this.props.adaptedFormat(line.cutToSize.width + 'X' + line.cutToSize.height, {
                                                                        upper: true,
                                                                        spaced: true,
                                                                        showUnits: true
                                                                    })}
                                                                        {/*{line.cutToSize.width + ' X ' + line.cutToSize.height} cm*/}
                                                                </span>
                                                                </div>
                                                            </div>
                                                            <div className="mini_cart_line_info__line">
                                                                <div className="subtitle">
                                                                    <i className="fas fa-sort-amount-down" />
                                                                    <span> {line.cutToSize.thickness} cm </span>
                                                                </div>
                                                            </div>
                                                            {
                                                                line.cutToSize.addon === 'MAL' &&
                                                                <div className="mini_cart_line_info__line">
                                                                    <div className="subtitle">
                                                                        <Translation id='MAL' />
                                                                    </div>
                                                                </div>
                                                            }
                                                        </React.Fragment>
                                                    )}
                                                    {line.cutToSize && line.cutToSize.attachedDoc && (
                                                        <div className="mini_cart_line_info__line">
                                                            <div className="subtitle">
                                                                <i className="far fa-file" />
                                                                <span> {line.cutToSize.attachedDoc}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className="cartline-actions">
                                            {!(line.bundleId || line.lotId) &&
                                                type !== 'recurring_orders' &&
                                                ((line.extra && line.extra.type === 'brochures') || (line.extra && line.extra.type === 'custom_sample') ? (
                                                    <React.Fragment>
                                                        {line.extra.type === 'custom_sample' && (
                                                            <div className="mini_cart_line_info__line no-capitalize">
                                                                <Translation
                                                                    id="pack_of_qutantity"
                                                                    defaultMessage="{packs} Pack of {quantity}"
                                                                    values={{ packs: '1', quantity: line.qty }}
                                                                />
                                                                &nbsp;
                                                                <Translation id="samples" defaultMessage="Samples" />
                                                            </div>
                                                        )}
                                                        {line.extra.type === 'brochures' && (
                                                            <div className="mini_cart_line_info__line no-capitalize">
                                                                <Translation
                                                                    id="pack_of_qutantity"

                                                                    defaultMessage="{packs} Pack of {quantity}"
                                                                    values={{ packs: '1', quantity: line.qty }}
                                                                />
                                                                &nbsp;
                                                                <Translation id="brochures" defaultMessage="Brochures" />
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <div className="mini_cart_line_info mini_cart_line_info--vertical_center">
                                                        {this.props.onlyView ? (
                                                            <div>
                                                                {line.extra && line.extra.itsFree && (
                                                                    <div>
                                                                    <span className="its_a_gift" style={{ color: 'darkred' }}>
                                                                        <Translation id="its_a_gift" defaultMessage="Its a gift!"></Translation>
                                                                    </span>
                                                                    </div>
                                                                )}
                                                                <span>
                                                                <Translation id="qty" defaultMessage="Qty"></Translation>: {line.qty}
                                                            </span>
                                                            </div>
                                                        ) : (
                                                            <InputNumber
                                                                value={line.qty || ''}
                                                                change={qty => {
                                                                    changeCartLineQty(line.lineId, qty)
                                                                }}
                                                                size="small"
                                                                step={isIkeaCenter && line.product.unit === 'M2' ? 0.01 : qtyToAdd}
                                                                limit={this.getLimit(line)}
                                                                min={this.getMin(line)}
                                                                decimals={isIkeaCenter && line.product.unit === 'M2' ? 2 : 0}
                                                                dataQas='checkout_product_quantity-add-remove-input'
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                    {launchDate > today && launchDate < todayPlus15Days && (
                                        <div className="row">
                                            <div className="col">
                                                <Alerts status="warning" alert="upcoming_product_alert"></Alerts>
                                            </div>
                                        </div>
                                    )}


                                    {this.getCheckoutType() !== 'ikea' && this.getCheckoutType() !== 'lowes' && (
                                        <React.Fragment>
                                            {(type === 'checkout' || type === 'recurring_orders') && !this.props.isMktoolsCenter && !this.props.isMktoolsUsaCenter &&
                                                <LineSegmentationV2 editSegmentation={this.editSegmentation} line={line} />
                                            }

                                            <div><span className='comment-title'><Translation id="comment" defaultMessage="Comments" />:</span> {line.comment ? line.comment.slice(0, 10) + '... ' : ''}
                                                {!this.state.showComment && (
                                                    <React.Fragment>
                                                        <span className='link' onClick={() => { this.setState({ showComment: true }) }}><Translation id="edit" defaultMessage="Edit" /></span>
                                                    </React.Fragment>
                                                )}
                                                {this.state.showComment && (
                                                    <span className='link' onClick={() => { this.setState({ showComment: false }) }}><Translation id="cancel" defaultMessage="Cancel" /></span>
                                                )}
                                            </div>

                                            {this.state.showComment && (
                                                <div className="comment">
                                                    <div className={`info ${!this.state.showComment ? 'info--hidden' : 'p-2 my-1'}`}>
                                                        <input
                                                            className="w-100"
                                                            type="text"
                                                            placeholder={this.props.intl.formatMessage({ id: 'comment' })}
                                                            ref={input => {
                                                                this.inputComment = input
                                                            }}
                                                            onChange={e => {
                                                                this.props.changeCartLineComment(line.lineId, e.target.value)
                                                            }}
                                                            onKeyDown={() => this.saveComment()}
                                                            onPaste={() => this.saveComment()}
                                                            value={line.comment}
                                                            maxLength="105"
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}

                                    {this.canViewSLA() && (
                                        <React.Fragment>
                                            <div className='date-sla'>
                                                {this.shouldShowSlaDate() && (
                                                    <span className='sla-date'>
                                                        {this.props.isLoadingLinesSlaDate ? (
                                                            <Spinner isLoading={true} relative small />
                                                        ) : (
                                                            this.props.isCheckoutStepOneButtonNextDisabled ? (
                                                                <Translation id="the_estimated_delivery_date_will_be_available_when_minimum_weight_reached" defaultMessage="The estimated delivery date will be available when the minimum weight is reached." />
                                                            ) : (
                                                                this.state.slaDate ? (
                                                                    <Translation id="estimated_delivery_on" defaultMessage="Estimated delivery on {date}" values={{ date: slaDate }} />
                                                                ) : (
                                                                    <Translation id="undefined_delivery_date" defaultMessage="Delivery date pending of review by Cosentino" />
                                                                )
                                                            )
                                                        )}
                                                    </span>
                                                )}

                                                {!this.props.isCheckoutStepOneButtonNextDisabled && (
                                                    <div>
                                                        <DatePicker
                                                            selected={storedDesiredDate ? storedDesiredDate : this.state.slaDate}
                                                            onChange={date => {
                                                                this.props.setLineDeliveryDate(line.lineId, date)
                                                                this.handleShowSlaDateNonCts(date, this.state.slaDate, line)
                                                                this.handleSetAllLinesDeliveryDatesCts(date, line, this.state.slaDate)
                                                                this.forceUpdate()
                                                            }}
                                                            minDate={this.props.selectedCenter == 1100 ? getNextWorkingDay(this.props.minDeliveryDate) : getNextWorkingDay(1)}
                                                            className="form-control"
                                                            autoComplete="off"
                                                            onKeyDown={e => e.preventDefault()}
                                                            dropdownMode="select"
                                                            customInput={<DesiredDatePicker />}
                                                            disabled={isDisabledPreferedDeliveryDatePicker}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {this.state.slaDate && linesDeliveryDates[line.lineId] && this.state.slaDate.isAfter(linesDeliveryDates[line.lineId].endOf('day')) && (
                                                <div className="prefered-date-advertisement">
                                                    <i className="mt-1 far fa-exclamation-triangle fa-1x"></i>
                                                    <Translation
                                                        id="desired_date_advertisement"
                                                        defaultMessage="You have chosen a different date than the estimated delivery. We cannot guarantee this delivery date, but we will try to accommodate your request"
                                                    />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}


                                    <div className='delete-add-fav'>
                                        <span className='link' onClick={() => onDelete(line.lineId)}>
                                            <i className="fal fa-trash-alt" />
                                            <Translation  id="delete" defaultMessage="Delete" />
                                        </span>
                                        <span className='link' onClick={() => onToggleFavourite(line.product.productId)}>
                                            {
                                                this.props.isFavouriteById(line.productId) ? (
                                                    <React.Fragment>
                                                        <i style={{color: 'red'}} className="fas fa-heart"/>
                                                        <Translation  id="remove_favourite" defaultMessage="Remove from favorites" />
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <i className="far fa-heart"/>
                                                        <Translation  id="add_favourite" defaultMessage="Add to favorites" />
                                                    </React.Fragment>
                                                )
                                            }
                                        </span>
                                    </div>


                                    <div className="mini_cart_line_price_phone">
                                        {!line.product.pricesMktools && !(line.extra && line.extra.itsFree) && (
                                            <React.Fragment>
                                                {this.props.canViewPricesPhase2 ? (
                                                    <div className="mini_cart_line_info__line"
                                                         style={{ display: 'block' }}
                                                    >
                                                        {this.props.calculatingLinesPrice ? (
                                                            <Spinner isLoading={true} relative small></Spinner>
                                                        ) : (
                                                            <React.Fragment>
                                                                <span className='total'>

                                                                    {(this.getNewPricePerUnit() && this.getNewPrice() == 0) || !this.getNewPrice() || !this.hasZT00Price() ? (
                                                                        <Translation id="not_available" defaultMessage="Not available" />
                                                                    ) : (
                                                                        this.getNewPrice()
                                                                    )}{' '}
                                                                    {this.props.linesPrice &&
                                                                        this.props.linesPrice[line.lineId] &&
                                                                        !this.getNewPrice() == 0 &&
                                                                        this.hasZT00Price() && <React.Fragment>{this.props.linesPrice[line.lineId].coin}</React.Fragment>}
                                                                </span>
                                                                <br />
                                                                <span className='price d-flex align-items-center'>
                                                                    {
                                                                        this.isOutdated() && (
                                                                            <span
                                                                                data-tip={this.props.intl.formatMessage({
                                                                                    id: 'project_expired_prices_not_charged',
                                                                                    defaultMessage: 'Project is expired. Prices for this line are not charged.'
                                                                                })}
                                                                            >
                                                                                <i className="alert-signal far fa-exclamation-triangle" />
                                                                                <ReactTooltip bg-coloreffect="solid"
                                                                                              overridePosition={ (
                                                                                                  { left, top },
                                                                                                  currentEvent, currentTarget, node) => {
                                                                                                  const d = document.documentElement;
                                                                                                  left = Math.min(d.clientWidth - node.clientWidth, left);
                                                                                                  top = Math.min(d.clientHeight - node.clientHeight, top);
                                                                                                  left = Math.max(8, left);
                                                                                                  top = Math.max(0, top);
                                                                                                  return {top, left}
                                                                                              }}
                                                                                />
                                                                                &nbsp;&nbsp;
                                                                            </span>
                                                                        )
                                                                    }
                                                                    <b>
                                                                        <Translation id="price" defaultMessage="Price" />:&nbsp;
                                                                    </b>
                                                                    {(this.getNewPricePerUnit() && this.getNewPricePerUnit() == 0) ||
                                                                    !this.getNewPricePerUnit() ||
                                                                    !this.hasZT00Price() ? (
                                                                        <Translation id="not_available" defaultMessage="Not available" />
                                                                    ) : (
                                                                        this.getNewPricePerUnit()
                                                                    )}{' '}
                                                                    {this.props.linesPrice &&
                                                                        this.props.linesPrice[line.lineId] &&
                                                                        !this.getNewPricePerUnit() == 0 &&
                                                                        this.hasZT00Price() && (
                                                                            <React.Fragment>
                                                                                {this.props.linesPrice[line.lineId].coin} / {this.props.linesPrice[line.lineId].unit}
                                                                            </React.Fragment>
                                                                        )}
                                                                    </span>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="mini_cart_line_info__line">
                                                        {!(line.extra && line.extra.outlet) && (
                                                            <DisplayPrice
                                                                userPreferences={this.props.userPreferences}
                                                                priceList={this.props.priceList}
                                                                userPriceList={this.props.userPriceList}
                                                                product={line.product}
                                                                isMktoolsCenter={this.props.isMktoolsCenter}
                                                                centerId={this.props.centerId}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>

                                    <div className="cartline-actions-phone">
                                        {!(line.bundleId || line.lotId) &&
                                            type !== 'recurring_orders' &&
                                            ((line.extra && line.extra.type === 'brochures') || (line.extra && line.extra.type === 'custom_sample') ? (
                                                <React.Fragment>
                                                    {line.extra.type === 'custom_sample' && (
                                                        <div className="mini_cart_line_info__line no-capitalize">
                                                            <Translation
                                                                id="pack_of_qutantity"
                                                                defaultMessage="{packs} Pack of {quantity}"
                                                                values={{ packs: '1', quantity: line.qty }}
                                                            />
                                                            &nbsp;
                                                            <Translation id="samples" defaultMessage="Samples" />
                                                        </div>
                                                    )}
                                                    {line.extra.type === 'brochures' && (
                                                        <div className="mini_cart_line_info__line no-capitalize">
                                                            <Translation
                                                                id="pack_of_qutantity"

                                                                defaultMessage="{packs} Pack of {quantity}"
                                                                values={{ packs: '1', quantity: line.qty }}
                                                            />
                                                            &nbsp;
                                                            <Translation id="brochures" defaultMessage="Brochures" />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <div className="mini_cart_line_info mini_cart_line_info--vertical_center">
                                                    {this.props.onlyView ? (
                                                        <div>
                                                            {line.extra && line.extra.itsFree && (
                                                                <div>
                                                                    <span className="its_a_gift" style={{ color: 'darkred' }}>
                                                                        <Translation id="its_a_gift" defaultMessage="Its a gift!"></Translation>
                                                                    </span>
                                                                </div>
                                                            )}
                                                            <span>
                                                                <Translation id="qty" defaultMessage="Qty"></Translation>: {line.qty}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <InputNumber
                                                            value={line.qty || ''}
                                                            change={qty => {
                                                                changeCartLineQty(line.lineId, qty)
                                                            }}
                                                            size="small"
                                                            step={isIkeaCenter && line.product.unit === 'M2' ? 0.01 : qtyToAdd}
                                                            limit={this.getLimit(line)}
                                                            min={this.getMin(line)}
                                                            decimals={isIkeaCenter && line.product.unit === 'M2' ? 2 : 0}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                            <div className='delete-add-fav-phone' style={{ display: 'flex'}}>
                                            <span className='link' onClick={() => onDelete(line.lineId)}><i style={{marginRight: '8px'}} className="mt-1 fal fa-trash-alt"></i> <Translation  id="delete" defaultMessage="Delete" /></span>
                                            <span className='separator' style={{marginLeft: '8px', marginRight: '8px'}}>|</span>

                                            <span className='link' onClick={() => onToggleFavourite(line.product.productId)}>
                                                {
                                                    this.props.isFavouriteById(line.productId) ? (
                                                        <React.Fragment>
                                                            <i style={{marginRight: '8px', color: 'red'}} className="mt-1 fas fa-heart"/>
                                                            <Translation  id="remove_favourite" defaultMessage="Remove from favorites" />
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <i style={{marginRight: '8px'}} className="mt-1 far fa-heart"/>
                                                            <Translation  id="add_favourite" defaultMessage="Add to favorites" />
                                                        </React.Fragment>
                                                    )
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        //BLOCK checkout summary v2
                        typeLine && typeLine === 'checkout_v2_summary' ? (
                            <div className='line-item checkout' key={line.lineId}>
                                <div className="img">
                                    <DisplayTechnology technology={line.product.technology} />
                                    <div className="indicators">
                                        {line.extra && line.extra.outlet && (
                                            <DisplayOutletIndicator
                                                outletQuality={line.extra.outlet}
                                                outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? this.product.outletDeviated : null}
                                            />
                                        )}
                                    </div>

                                    {image}
                                </div>
                                <div className="content">
                                    <div className="description-and-input">
                                        <div className="mini_cart_line_title">
                                            <div className="title">
                                                <LocalizedLink routeId="ROUTE_PRODUCT_DETAIL"
                                                               params={{ id: line.product.productId, outlet: line.extra && !!line.extra.outlet ? 'outlet' : ''}}
                                                               onClick={() => this.props.setLastSelectedProduct(line.product, line.extra)}
                                                >
                                                    <span className="title">
                                                        {line.cutToSize ? line.cutToSize.colorName : <ProductDisplayName product={line.product} />}
                                                    </span>
                                                </LocalizedLink>
                                                &nbsp;
                                                <span className="mini_cart_line_title--brand">
                                                    <DisplayBrand brand={line.brand} span />
                                                </span>

                                                <span style={{fontWeight: '300'}}>
                                                    <DisplayPriceMktools prices={line.product.pricesMktools} group={this.props.priceGroup} />
                                                </span>

                                                {launchDate && line.product.stock == 0 && launchDate > today && launchDate < todayPlus15Days && (
                                                    <div className="spheres-indicator">
                                                        <DisplayUpcomingIndicator />
                                                    </div>
                                                )}
                                            </div>
                                            {(line.bundleId || line.lotId) && (
                                                <div className="lot_id">
                                                    {' '}
                                                    {line.bundleId ? (
                                                            <span className="quantity-label bundle-content " onClick={() => onOpenBundleContent(line)}>
                                                                <i className="fal fa-list" /> <Translation id="bundle_id" defaultMessage="bundle_id" /> {line.bundleId}
                                                            </span>
                                                        ) : line.lotId ? (
                                                            <span className="quantity-label">
                                                                <DisplayLotId lotId={line.lotId} withDescription></DisplayLotId>
                                                            </span>
                                                        ) : null}
                                                </div>
                                            )}
                                        </div>
                                        <div className="mini_cart_line_price">
                                            {!line.product.pricesMktools && !(line.extra && line.extra.itsFree) && (
                                                <React.Fragment>
                                                    {this.props.canViewPricesPhase2 ? (
                                                        <div className="mini_cart_line_info__line" style={{ display: 'block' }}>
                                                            {this.props.calculatingLinesPrice ? (
                                                                <Spinner isLoading={true} relative small></Spinner>
                                                            ) : (
                                                                <React.Fragment>
                                                                    <span className='total'>
                                                                        <div className='d-flex align-items-center justify-content-start'>
                                                                            {
                                                                                this.isOutdated() && (
                                                                                    <span
                                                                                        data-tip={this.props.intl.formatMessage({
                                                                                            id: 'project_expired_prices_not_charged',
                                                                                            defaultMessage: 'Project is expired. Prices for this line are not charged.'
                                                                                        })}
                                                                                    >
                                                                                        <i className="alert-signal far fa-exclamation-triangle"/>
                                                                                        <ReactTooltip bg-coloreffect="solid" place="bottom" />
                                                                                        &nbsp;&nbsp;
                                                                                    </span>
                                                                                )
                                                                            }
                                                                            {(this.getNewPricePerUnit() && this.getNewPrice() == 0) || !this.getNewPrice() || !this.hasZT00Price() ? (
                                                                                <Translation id="not_available" defaultMessage="Not available" />
                                                                            ) : (
                                                                                this.getNewPrice()
                                                                            )}{' '}
                                                                            {this.props.linesPrice &&
                                                                                this.props.linesPrice[line.lineId] &&
                                                                                !this.getNewPrice() == 0 &&
                                                                                this.hasZT00Price() && (
                                                                                    <React.Fragment>
                                                                                        {this.props.linesPrice[line.lineId].coin}
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="mini_cart_line_info__line">
                                                            {!(line.extra && line.extra.outlet) && (
                                                                <DisplayPrice
                                                                    userPreferences={this.props.userPreferences}
                                                                    priceList={this.props.priceList}
                                                                    userPriceList={this.props.userPriceList}
                                                                    product={line.product}
                                                                    isMktoolsCenter={this.props.isMktoolsCenter}
                                                                    centerId={this.props.centerId}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className="mini_cart_line_quantity">
                                            <Translation id="quantity" defaultMessage="Quantity" />: <span className="total-quantity">{line.qty}</span>
                                        </div>
                                        <div className="mini_cart_line_block">
                                            <div className="mini_cart_line_info">
                                                {!line.cutToSize && line.product.subtypeName && (
                                                    <div className="mini_cart_line_info__line">
                                                        <span> {line.product.subtypeName} </span>
                                                    </div>
                                                )}
                                                {line.extra && line.extra.type && (
                                                    <div className="mini_cart_line_info__line">
                                                        <span> {intl.formatMessage({ id: line.extra.type })} </span>
                                                    </div>
                                                )}
                                                {line.extra && line.extra.brand && <DisplayBrand brand={line.extra.brand}></DisplayBrand>}
                                                {line.extra && line.extra.colorName && (
                                                    <div className="mini_cart_line_info__line">
                                                        <span>{line.extra.colorName}</span>
                                                    </div>
                                                )}
                                                {line.cutToSize && line.cutToSize.elaboration && (
                                                    <div className="mini_cart_line_info__line">
                                                        <div className="subtitle">
                                                            <span> {intl.formatMessage(ElaborationOptionsMap[line.cutToSize.elaboration].name)} </span>
                                                        </div>
                                                    </div>
                                                )}
                                                {!line.cutToSize && line.product.finish && (
                                                    <div className="mini_cart_line_info__line">
                                                        <span>
                                                            <Translation id={line.product.finish} />
                                                        </span>
                                                    </div>
                                                )}
                                                {line.cutToSize && line.cutToSize.finish && (
                                                    <div className="mini_cart_line_info__line">
                                                        <div className="subtitle">
                                                            <Translation id={line.cutToSize.finish} />
                                                        </div>
                                                    </div>
                                                )}
                                                {line.extra && line.extra.height && line.extra.width && (
                                                    <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                                <span>
                                                    <i className="fas fa-window-maximize" />
                                                    &nbsp;
                                                    {this.props.adaptedFormat(line.extra.height + 'X' + line.extra.width, { upper: true, spaced: true })}
                                                </span>
                                                    </div>
                                                )}
                                                {line.product.thickness && !line.cutToSize && (
                                                    <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                                <span style={{ margin: '0' }}>
                                                    <i className="fas fa-sort-amount-down" />
                                                    {line.product.thickness} cm.{' '}
                                                </span>
                                                    </div>
                                                )}

                                                {line.product.zaddon === 'MAL' && (
                                                    <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                                        <Translation id='MAL' />
                                                    </div>
                                                )}

                                                {line.extra && line.extra.mall && (
                                                    <div style={{ position: 'relative' }} className="mini_cart_line_info__line wrapper__logo_mall">
                                                        <img style={{ width: '45px' }} width="45" src={mallLarge} alt="logo mall" />
                                                    </div>
                                                )}
                                                {type === 'recurring_orders' &&
                                                    (((line.extra && line.extra.type === 'wall_sample') || (line.extra && line.extra.type === 'custom_sample')) && (
                                                        <div className="mini_cart_line_info__line">
                                                            <Translation id="quantity" defaultMessage="Quantity" />: {line.qty}
                                                        </div>
                                                    ))}
                                                {line.extra && line.extra.lot && (
                                                    <React.Fragment>
                                                        <div className="mini_cart_line_info__line">
                                                            <Translation id="quarryBlock" defaultMessage="Quarry Block" />:{' '}
                                                            <span className="quarry_block">{line.extra.lot.quarryBlock}</span>
                                                        </div>
                                                        <div className="mini_cart_line_info__line">
                                                            <Translation id="quality" defaultMessage="Quality" />: <span className="quality">{line.extra.lot.quality}</span>
                                                        </div>
                                                        <div className="mini_cart_line_info__line">
                                                            <Translation id="shade" defaultMessage="Shade" />: <span className="quality">{line.extra.lot.shade}</span>
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                                {/*{line.product.pricesMktools && (*/}
                                                {/*    <div className="mini_cart_line_info__line">*/}
                                                {/*        <i className="fas" />*/}
                                                {/*        <DisplayPriceMktools prices={line.product.pricesMktools} group={this.props.priceGroup} />{' '}*/}
                                                {/*    </div>*/}
                                                {/*)}*/}

                                                {line.cutToSize && (
                                                    <React.Fragment>
                                                        {line.cutToSize.width && line.cutToSize.height && line.cutToSize.thickness && (
                                                            <React.Fragment>
                                                                <div className="mini_cart_line_info__line">
                                                                    <div className="subtitle">
                                                                        <i className="fas fa-window-maximize" />
                                                                        &nbsp;
                                                                        <span>
                                                                    {this.props.adaptedFormat(line.cutToSize.width + 'X' + line.cutToSize.height, {
                                                                        upper: true,
                                                                        spaced: true,
                                                                        showUnits: true
                                                                    })}
                                                                            {/*{line.cutToSize.width + ' X ' + line.cutToSize.height} cm*/}
                                                                </span>
                                                                    </div>
                                                                </div>
                                                                <div className="mini_cart_line_info__line">
                                                                    <div className="subtitle">
                                                                        <i className="fas fa-sort-amount-down" />
                                                                        <span> {line.cutToSize.thickness} cm </span>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    line.cutToSize.addon === 'MAL' &&
                                                                    <div className="mini_cart_line_info__line">
                                                                        <div className="subtitle">
                                                                            <Translation id='MAL' />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        )}
                                                        {line.cutToSize && line.cutToSize.attachedDoc && (
                                                            <div className="mini_cart_line_info__line">
                                                                <div className="subtitle">
                                                                    <i className="far fa-file" />
                                                                    <span> {line.cutToSize.attachedDoc}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>

                                        {this.canViewSLA() &&
                                            (this.props.isLoadingLinesSlaDate ? (
                                                <Spinner isLoading={true} relative small />
                                            ) : this.props.isCheckoutStepOneButtonNextDisabled ? (
                                                <div className="estimated_date_block">
                                                    <Translation id="the_estimated_delivery_date_will_be_available_when_minimum_weight_reached" defaultMessage="The estimated delivery date will be available when the minimum weight is reached." />
                                                </div>
                                            ) : this.state.slaDate ? (
                                                <div className="estimated_date_block">
                                                    <Translation id="estimated_delivery_on" defaultMessage="Estimated delivery on {date}" values={{ date: this.showLongestDeliveryDate(storedDesiredDate, this.state.slaDate) }} />
                                                </div>
                                            ) : (
                                                <div className="estimated_date_block">
                                                    <Translation id="undefined_delivery_date" defaultMessage="Delivery date pending of review by Cosentino" />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : (
                            // BLOCK checkout v2?
                            <div className={type === 'checkout' || type === 'recurring_orders' ? 'line-item checkout' : 'line-item'} key={line.lineId}>
                                <div className="img">
                                    <DisplayTechnology technology={line.product.technology} />
                                    <div className="indicators">
                                        {line.extra && line.extra.outlet && (
                                            <DisplayOutletIndicator
                                                outletQuality={line.extra.outlet}
                                                outletDeviated={this.props.isAdmin || this.props.impersonatedBy ? this.product.outletDeviated : null}
                                            />
                                        )}
                                    </div>

                                    {image}
                                </div>
                                <div className="content">
                                    <div className="description-and-input">
                                        <div className="mini_cart_line_title">
                                            <div className="title">
                                                <LocalizedLink routeId="ROUTE_PRODUCT_DETAIL"
                                                               params={{ id: line.product.productId, outlet: line.extra && !!line.extra.outlet ? 'outlet' : ''}}
                                                               onClick={() => this.props.setLastSelectedProduct(line.product, line.extra)}
                                                >
                                                    <span className="title">{line.cutToSize ? line.cutToSize.colorName : <ProductDisplayName product={line.product} />}</span>
                                                </LocalizedLink>{' '}
                                                <span className="subtitle">
                                        <DisplayBrand brand={line.brand} span></DisplayBrand>
                                    </span>
                                            {launchDate && line.product.stock == 0 && launchDate > today && launchDate < todayPlus15Days && (
                                                <div className="spheres-indicator">
                                                    <DisplayUpcomingIndicator />
                                                </div>
                                            )}
                                        </div>
                                        <div className="lot_id">
                                            {' '}
                                            {line.bundleId ? (
                                                <span className="quantity-label bundle-content " onClick={() => onOpenBundleContent(line)}>
                                            <i className="fal fa-list" /> <Translation id="bundle_id" defaultMessage="bundle_id" /> {line.bundleId}
                                        </span>
                                            ) : line.lotId ? (
                                                <span className="quantity-label">
                                            <DisplayLotId lotId={line.lotId} withDescription></DisplayLotId>
                                        </span>
                                            ) : null}
                                        </div>
                                        <div className="cartline-actions">
                                            {!(line.bundleId || line.lotId) &&
                                                type !== 'recurring_orders' &&
                                                ((line.extra && line.extra.type === 'brochures') || (line.extra && line.extra.type === 'custom_sample') ? (
                                                    <React.Fragment>
                                                        {line.extra.type === 'custom_sample' && (
                                                            <div className="mini_cart_line_info__line no-capitalize">
                                                                <Translation
                                                                    id="pack_of_qutantity"
                                                                    defaultMessage="{packs} Pack of {quantity}"
                                                                    values={{ packs: '1', quantity: line.qty }}
                                                                />
                                                                &nbsp;
                                                                <Translation id="samples" defaultMessage="Samples" />
                                                            </div>
                                                        )}
                                                        {line.extra.type === 'brochures' && (
                                                            <div className="mini_cart_line_info__line no-capitalize">
                                                                <Translation
                                                                    id="pack_of_qutantity"

                                                                    defaultMessage="{packs} Pack of {quantity}"
                                                                    values={{ packs: '1', quantity: line.qty }}
                                                                />
                                                                &nbsp;
                                                                <Translation id="brochures" defaultMessage="Brochures" />
                                                            </div>
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <div className="mini_cart_line_info mini_cart_line_info--vertical_center">
                                                        {this.props.onlyView ? (
                                                            <div>
                                                                {line.extra && line.extra.itsFree && (
                                                                    <div>
                                                                <span className="its_a_gift" style={{ color: 'darkred' }}>
                                                                    <Translation id="its_a_gift" defaultMessage="Its a gift!"></Translation>
                                                                </span>
                                                                    </div>
                                                                )}
                                                                <span>
                                                            <Translation id="qty" defaultMessage="Qty"></Translation>: {line.qty}
                                                        </span>
                                                            </div>
                                                        ) : (
                                                            <InputNumber
                                                                value={line.qty || ''}
                                                                change={qty => {
                                                                    changeCartLineQty(line.lineId, qty)
                                                                }}
                                                                size="small"
                                                                step={isIkeaCenter && line.product.unit === 'M2' ? 0.01 : qtyToAdd}
                                                                limit={this.getLimit(line)}
                                                                min={this.getMin(line)}
                                                                decimals={isIkeaCenter && line.product.unit === 'M2' ? 2 : 0}
                                                            />
                                                        )}
                                                    </div>
                                                ))}
                                            {type === 'recurring_orders'
                                                ? null
                                                : !(line.extra && line.extra.itsFree) && (
                                                <div className="delete-button">
                                                    <div>
                                                        <div className="tooltip">
                                                          <span
                                                              className="bt-edit"
                                                              onClick={() => {
                                                                  this.setState({ showComment: !this.state.showComment })
                                                              }}
                                                              style={{ cursor: 'pointer' }}
                                                          >
                                                              <i className="fal fa-comment-edit custom-icon" />
                                                          </span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="tooltip">
                                                            {!this.props.onlyView &&
                                                                !isMktoolsCenter &&
                                                                !isIkeaCenter &&
                                                                !(line.extra && (line.extra.type === 'custom_sample' || line.extra.type === 'brochures')) && (
                                                                    <span
                                                                        className="bt-edit"
                                                                        onClick={() => {
                                                                            this.editSegmentation()
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                      <i className="fal fa-edit" />
                                                                  </span>
                                                                )}
                                                            {(type === 'checkout' || type === 'recurring_orders') && (
                                                                <span className="tooltiptext">
                                                                  <Translation id="edit_segmentation" defaultMessage="Edit segmentation" />
                                                              </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {!this.props.onlyView && (
                                                        <div>
                                                            <div className="tooltip">
                                                              <span
                                                                  className="bt-delete"
                                                                  onClick={() => {
                                                                      onDelete(line.lineId)
                                                                  }}
                                                                  style={{ cursor: 'pointer' }}
                                                              >
                                                                  <i className="fal fa-times" />
                                                              </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mini_cart_line_block">
                                        <div className="mini_cart_line_info">
                                            {!line.cutToSize && line.product.subtypeName && (
                                                <div className="mini_cart_line_info__line">
                                                    <span> {line.product.subtypeName} </span>
                                                </div>
                                            )}
                                            {line.extra && line.extra.type && (
                                                <div className="mini_cart_line_info__line">
                                                    <span> {intl.formatMessage({ id: line.extra.type })} </span>
                                                </div>
                                            )}
                                            {line.extra && line.extra.brand && <DisplayBrand brand={line.extra.brand}></DisplayBrand>}
                                            {line.extra && line.extra.colorName && (
                                                <div className="mini_cart_line_info__line">
                                                    <span>{line.extra.colorName}</span>
                                                </div>
                                            )}
                                            {line.cutToSize && line.cutToSize.elaboration && (
                                                <div className="mini_cart_line_info__line">
                                                    <div className="subtitle">
                                                        <span> {intl.formatMessage(ElaborationOptionsMap[line.cutToSize.elaboration].name)} </span>
                                                    </div>
                                                </div>
                                            )}
                                            {!line.cutToSize && line.product.finish && (
                                                <div className="mini_cart_line_info__line">
                                                    <span>
                                                        <Translation id={line.product.finish} />
                                                    </span>
                                                </div>
                                            )}
                                            {line.cutToSize && line.cutToSize.finish && (
                                                <div className="mini_cart_line_info__line">
                                                    <div className="subtitle">
                                                        <Translation id={line.cutToSize.finish} />
                                                    </div>
                                                </div>
                                            )}
                                            {line.extra && line.extra.height && line.extra.width && (
                                                <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                            <span>
                                                <i className="fas fa-window-maximize" />
                                                &nbsp;
                                                {this.props.adaptedFormat(line.extra.height + 'X' + line.extra.width, { upper: true, spaced: true })}
                                            </span>
                                                </div>
                                            )}
                                            {line.product.thickness && !line.cutToSize && (
                                                <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                            <span style={{ margin: '0' }}>
                                                <i className="fas fa-sort-amount-down" />
                                                {line.product.thickness} cm.{' '}
                                            </span>
                                                </div>
                                            )}

                                            {line.product.zaddon === 'MAL' && (
                                                <div className="mini_cart_line_info__line mini_cart_line_info__line--dimensions">
                                                    <Translation id='MAL' />
                                                </div>
                                            )}

                                            {line.extra && line.extra.mall && (
                                                <div style={{ position: 'relative' }} className="mini_cart_line_info__line wrapper__logo_mall">
                                                    <img style={{ width: '45px' }} width="45" src={mallLarge} alt="logo mall" />
                                                </div>
                                            )}
                                            {type === 'recurring_orders' &&
                                                (((line.extra && line.extra.type === 'wall_sample') || (line.extra && line.extra.type === 'custom_sample')) && (
                                                    <div className="mini_cart_line_info__line">
                                                        <Translation id="quantity" defaultMessage="Quantity" />: {line.qty}
                                                    </div>
                                                ))}
                                            {line.extra && line.extra.lot && (
                                                <React.Fragment>
                                                    <div className="mini_cart_line_info__line">
                                                        <Translation id="quarryBlock" defaultMessage="Quarry Block" />:{' '}
                                                        <span className="quarry_block">{line.extra.lot.quarryBlock}</span>
                                                    </div>
                                                    <div className="mini_cart_line_info__line">
                                                        <Translation id="quality" defaultMessage="Quality" />: <span className="quality">{line.extra.lot.quality}</span>
                                                    </div>
                                                    <div className="mini_cart_line_info__line">
                                                        <Translation id="shade" defaultMessage="Shade" />: <span className="quality">{line.extra.lot.shade}</span>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            {line.product.pricesMktools && (
                                                <div className="mini_cart_line_info__line">
                                                    <i className="fas" />
                                                    <DisplayPriceMktools prices={line.product.pricesMktools} group={this.props.priceGroup} />{' '}
                                                </div>
                                            )}

                                            {line.cutToSize && (
                                                <React.Fragment>
                                                    {line.cutToSize.width && line.cutToSize.height && line.cutToSize.thickness && (
                                                        <React.Fragment>
                                                            <div className="mini_cart_line_info__line">
                                                                <div className="subtitle">
                                                                    <i className="fas fa-window-maximize" />
                                                                    &nbsp;
                                                                    <span>
                                                                {this.props.adaptedFormat(line.cutToSize.width + 'X' + line.cutToSize.height, {
                                                                    upper: true,
                                                                    spaced: true,
                                                                    showUnits: true
                                                                })}
                                                                        {/*{line.cutToSize.width + ' X ' + line.cutToSize.height} cm*/}
                                                            </span>
                                                                </div>
                                                            </div>
                                                            <div className="mini_cart_line_info__line">
                                                                <div className="subtitle">
                                                                    <i className="fas fa-sort-amount-down" />
                                                                    <span> {line.cutToSize.thickness} cm </span>
                                                                </div>
                                                            </div>
                                                            {
                                                                line.cutToSize.addon === 'MAL' &&
                                                                <div className="mini_cart_line_info__line">
                                                                    <div className="subtitle">
                                                                        <Translation id='MAL' />
                                                                    </div>
                                                                </div>
                                                            }

                                                        </React.Fragment>
                                                    )}
                                                    {line.cutToSize && line.cutToSize.attachedDoc && (
                                                        <div className="mini_cart_line_info__line">
                                                            <div className="subtitle">
                                                                <i className="far fa-file" />
                                                                <span> {line.cutToSize.attachedDoc}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                        <div className="mini_cart_line_price">
                                            {!line.product.pricesMktools && !(line.extra && line.extra.itsFree) && (
                                                <React.Fragment>
                                                    {this.props.canViewPricesPhase2 ? (
                                                        <div className="mini_cart_line_info__line" style={{ display: 'block' }}>
                                                            {this.props.calculatingLinesPrice ? (
                                                                <Spinner isLoading={true} relative small></Spinner>
                                                            ) : (
                                                                <div className="d-flex align-items-center px-1"
                                                                     style={this.isOutdated() ? {backgroundColor:"yellow", columnGap:"6px"} : {}}>
                                                                    {
                                                                        this.isOutdated() && (
                                                                            <span
                                                                                data-tip={this.props.intl.formatMessage({
                                                                                    id: 'project_expired_prices_not_charged',
                                                                                    defaultMessage: 'Project is expired. Prices for this line are not charged.'
                                                                                })}
                                                                            >
                                                                                <i className="far fa-exclamation-triangle fa-2x" />
                                                                                <ReactTooltip bg-coloreffect="solid" place="bottom" />
                                                                            </span>
                                                                        )
                                                                    }
                                                                    <React.Fragment>
                                                                        <React.Fragment>
                                                                            <b>
                                                                                <Translation id="price" defaultMessage="Price" />:
                                                                            </b>{' '}
                                                                            {(this.getNewPricePerUnit() && this.getNewPricePerUnit() == 0) ||
                                                                            !this.getNewPricePerUnit() ||
                                                                            !this.hasZT00Price() ? (
                                                                                <Translation id="not_available" defaultMessage="Not available" />
                                                                            ) : (
                                                                                this.getNewPricePerUnit()
                                                                            )}{' '}
                                                                            {this.props.linesPrice &&
                                                                                this.props.linesPrice[line.lineId] &&
                                                                                !this.getNewPricePerUnit() == 0 &&
                                                                                this.hasZT00Price() && (
                                                                                    <React.Fragment>
                                                                                        {this.props.linesPrice[line.lineId].coin} / {this.props.linesPrice[line.lineId].unit}
                                                                                    </React.Fragment>
                                                                                )}
                                                                        </React.Fragment>
                                                                        <br />
                                                                        <React.Fragment>
                                                                            <b>Total:</b>{' '}
                                                                            {(this.getNewPricePerUnit() && this.getNewPrice() == 0) || !this.getNewPrice() || !this.hasZT00Price() ? (
                                                                                <Translation id="not_available" defaultMessage="Not available" />
                                                                            ) : (
                                                                                this.getNewPrice()
                                                                            )}{' '}
                                                                            {this.props.linesPrice &&
                                                                                this.props.linesPrice[line.lineId] &&
                                                                                !this.getNewPrice() == 0 &&
                                                                                this.hasZT00Price() && <React.Fragment>{this.props.linesPrice[line.lineId].coin}</React.Fragment>}
                                                                        </React.Fragment>
                                                                    </React.Fragment>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className="mini_cart_line_info__line">
                                                            {!(line.extra && line.extra.outlet) && (
                                                                <DisplayPrice
                                                                    userPreferences={this.props.userPreferences}
                                                                    priceList={this.props.priceList}
                                                                    userPriceList={this.props.userPriceList}
                                                                    product={line.product}
                                                                    isMktoolsCenter={this.props.isMktoolsCenter}
                                                                    centerId={this.props.centerId}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </div>
                                    {launchDate > today && launchDate < todayPlus15Days && (
                                        <div className="row">
                                            <div className="col">
                                                <Alerts status="warning" alert="upcoming_product_alert"></Alerts>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.showComment && (
                                        <div className="comment">
                                            <div className={`info ${!this.state.showComment ? 'info--hidden' : 'p-2 my-1'}`}>
                                                <input
                                                    className="w-100"
                                                    type="text"
                                                    placeholder={this.props.intl.formatMessage({ id: 'comment' })}
                                                    ref={input => {
                                                        this.inputComment = input
                                                    }}
                                                    onChange={e => {
                                                        this.props.changeCartLineComment(line.lineId, e.target.value)
                                                    }}
                                                    onKeyDown={() => this.saveComment()}
                                                    onPaste={() => this.saveComment()}
                                                    value={line.comment}
                                                    maxLength="105"
                                                />
                                            </div>
                                        </div>
                                    )}

                                        {(type === 'checkout' || type === 'recurring_orders') && <LineSegmentation line={line} />}

                                        {this.canViewSLA() && (
                                            <React.Fragment>
                                                <div style={{ display: 'flex', columnGap: '12px' }}>
                                                    {this.shouldShowSlaDate() && (
                                                        <span className='sla-date'>
                                                            {this.props.isLoadingLinesSlaDate ? (
                                                                <Spinner isLoading={true} relative small />
                                                            ) : (
                                                                this.props.isCheckoutStepOneButtonNextDisabled ? (
                                                                    <Translation id="the_estimated_delivery_date_will_be_available_when_minimum_weight_reached" defaultMessage="The estimated delivery date will be available when the minimum weight is reached." />
                                                            ) : (
                                                                this.state.slaDate ? (
                                                                    <Translation id="estimated_delivery_on" defaultMessage="Estimated delivery on {date}" values={{ date: slaDate }} />
                                                                ) : (
                                                                    <Translation id="undefined_delivery_date" defaultMessage="Delivery date pending of review by Cosentino" />
                                                                )
                                                            )
                                                        )}
                                                        </span>
                                                    )}

                                                    {!this.props.isCheckoutStepOneButtonNextDisabled && (
                                                        <div>
                                                            <DatePicker
                                                                selected={storedDesiredDate ? storedDesiredDate : this.state.slaDate}
                                                                onChange={date => {
                                                                    this.props.setLineDeliveryDate(line.lineId, date)
                                                                    this.handleShowSlaDateNonCts(date, this.state.slaDate, line)
                                                                    this.handleSetAllLinesDeliveryDatesCts(date, line, this.state.slaDate)
                                                                    this.forceUpdate()
                                                                }}
                                                                minDate={this.props.selectedCenter == 1100 ? getNextWorkingDay(this.props.minDeliveryDate) :  getNextWorkingDay(1)}
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onKeyDown={e => e.preventDefault()}
                                                                dropdownMode="select"
                                                                customInput={<DesiredDatePicker />}
                                                                disabled={isDisabledPreferedDeliveryDatePicker}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                {this.state.slaDate && linesDeliveryDates[line.lineId] && this.state.slaDate.isAfter(linesDeliveryDates[line.lineId].endOf('day')) && (
                                                    <div className="prefered-date-advertisement">
                                                        <i className="mt-1 far fa-exclamation-triangle fa-2x"></i>
                                                        <Translation
                                                            id="desired_date_advertisement"
                                                            defaultMessage="You have chosen a different date than the estimated delivery. We cannot guarantee this delivery date, but we will try to accommodate your request"
                                                        />
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                </div>
                            </div>
                        </div>
                        )
                    )
                )}
            </React.Fragment>
        )
    }
}
export default injectIntl(CartLine)
